import React from "react";
import Wave from "react-wavify";

import { isBrowser } from "../services/general";

const WaveBackground = ({ state }) => {
    const getWindowHeight = () => {
        if (isBrowser()) return window.innerHeight / 1.3;
    }
    const CENTER_HEIGHT = getWindowHeight();

    return (
        <Wave
            fill = "url(#gradient)"
            paused={false}
            options={{
                height: CENTER_HEIGHT,
                amplitude: 6,
                speed: 0.5,
                points: 3
            }}
            style={{
                height: "100%",
            }}
        >
            <defs>
                {state.isDark ?
                    <linearGradient id="gradient" gradientTransform="rotate(90)">
                        <stop offset="5%" stopColor="#ADF1FF" />
                        <stop offset="5%" stopColor="#356586" />
                        <stop offset="90%" stopColor="#0C3256" />
                    </linearGradient>
                    :
                    <linearGradient id="gradient" gradientTransform="rotate(90)">
                        <stop offset="30%"  stopColor="#A0D2FF" />
                        <stop offset="90%" stopColor="#C7E4FF" />
                    </linearGradient>
                }
            </defs>
        </Wave>
    )
}

export default WaveBackground