import React, { useContext } from "react"
import { Link } from "gatsby"

import WaveBackground from "../components/WaveBackground"
import { AppContext } from "../context/AppContext"

const NotFoundPage = () => {
  let { state } = useContext(AppContext)

  return (
    <div className="not-found is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
      <p className="is-size-1 has-text-weight-bold">
        Oops!
      </p>
      <p className="is-size-4 mb-6">
        I think you're lost.
      </p>
      <Link to="/" style={{ textDecoration: "none" }}>
        <button className="button is-primary">
          Let's go back home.
        </button>
      </Link>
      <div className="bg">
        <WaveBackground state={state}/>
      </div>
    </div>
  )
}

export default NotFoundPage

export const Head = () => <title>oops!</title>